import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { useI18n } from 'i18n';
import { isBundle } from 'core/api/meta';

import { meta } from 'core/redux/api/meta';

import Loader from 'core/react/general/Loader';
import TagPicker from 'core/react/general/form/TagPicker';

import { ViewMode } from './DevicePropertiesView';

/**
 * Control for selecting cx.ods.meta.ComposableProperty.
 * @param {Object} props
 * @param {ViewMode} props.viewMode
 * @param {Array.<number>} props.value - propertyId
 * @param {function} props.onChange
 * @param {string} props.label
 * @param {boolean} [props.disabled]
 */
function DevicePropertyPicker(props) {
	const { f } = useI18n();
	let content = null;

	useEffect(() => {
		if (props.properties.map == null && !props.properties.pending) {
			props.dispatch(meta.properties.actions.load.request());
		}
	}, []);

	if (props.properties.list != null) {
		const items = props.properties.list.filter(property => {
			if (props.viewMode == ViewMode.Bundles) return isBundle(property);
			else if (props.viewMode == ViewMode.Properties) return !isBundle(property);
			return true;
		}).map(property => ({
			value: property.propertyId,
			label: property.name
		}));

		content = <TagPicker
			data={items}
			value={props.value}
			onChange={props.onChange}
			placeholder={f('select ' + (props.viewMode ? props.viewMode : ''))}
			label={props.label}
			disabled={props.disabled}
			style={{ minHeight: '37px' }}
		/>;
	} else content = <Loader />;

	return (
		<div className="device-property-picker">
			{content}
		</div>
	);
}

export default connect(state => {
	return {
		properties: state.meta.properties,
	}
})(DevicePropertyPicker);
