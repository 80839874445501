import React, { useImperativeHandle } from 'react';
import { useDispatch } from 'react-redux';
import { useI18n } from '../../../../../i18n';
import Form from '../../../general/form/Form';
import DevicePropertyPicker from '../../deviceProperties/DevicePropertyPicker';
import { ViewMode } from '../../deviceProperties/DevicePropertiesView';
import { actions } from '../../../../redux/api/reports';
import ObjectDeviceManager from '../../../share/devices/ObjectDeviceManager';
import './deviceRegistry.scss';

/**
 * @param {Object} props
 * @param {React.RefObject} props.customRef
 * @param {Object} [props.parameters] // { propertyIds: [], uris: [] }
 * @param {boolean} [props.disabled]
 */

function DeviceRegistry(props) {
	const { f } = useI18n();
	const dispatch = useDispatch();

	useImperativeHandle(props.customRef, () => ({
		submit: (values) => {
			dispatch(actions.deviceRegistry.generate({ parameters: { propertyIds: values.bundles, uris: values.uris } }));
		},
		clear: () => {
			dispatch(actions.deviceRegistry.exportClear());
		}
	}));

	return (
		<div className="device-registry renderer">
			<Form.ControlGroup key={50} disabled={props.disabled}>
				<Form.Control
					controlType={DevicePropertyPicker}
					controlName="bundles"
					label={f('templates restriction')}
					initialValue={props.parameters ? props.parameters.propertyIds : []}
					viewMode={ViewMode.Bundles}
				/>
				<ObjectDeviceManager
					disabled={props.disabled}
					editMode
					uris={props.parameters ? props.parameters.uris : []}
					controlName="uris"
					title={f('devices restriction')}
				/>
			</Form.ControlGroup>
		</div>
	);
}

export default DeviceRegistry;
