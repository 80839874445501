import { ignoreElements } from 'rxjs/operators';

import { ofType } from "redux-observable";

import { actions } from "../actions";

const epic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.deviceRegistry.generate.type)
		, ignoreElements()
	);
}

export { epic };
