import { actions } from '../actions';

const defaultState = {
	parameters: null
	, exporting: false
	, csv: null
};

function reducer(state = defaultState, action) {
	switch (action.type) {
		case actions.deviceRegistry.generate.type:
			return {
				...state, parameters: action.parameters
			};
		case actions.deviceRegistry.exportDone.type:
			return {
				...state, exporting: false, csv: action.csv
			}
		case actions.deviceRegistry.exportClear.type:
			return {
				...state, csv: null
			}
		default:
			return state;
	}
}

export { reducer };
