import { cx } from 'core/api';

export function isBundle(property) {
	return cx.o.typeOf(property, cx.ods.meta.PropertyBundle);
}

export function isVector(property) {
	return cx.o.typeOf(property, cx.ods.meta.PropertyVector);
}

export function isRecord(property) {
	return cx.o.typeOf(property, cx.ods.meta.PropertyRecord);
}
