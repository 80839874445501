import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { useI18n } from 'i18n';
import { cx } from 'core/api';
import { isBundle as isPropertyBundle } from 'core/api/meta';

import { actions } from 'core/redux/api/reports';

import { getTimezoneString, formatDateTime } from 'core/misc/misc';
import Loader from 'core/react/general/Loader';
import ScrollList from 'core/react/general/list/ScrollList';


import ReportHeader from './ReportHeader';
import ReportSummary from './ReportSummary';

import './deviceRegistry.scss';

let headerRows = []; // for storing all rows and columns of the table heading
let mainBodyColumns = []; // to store all table columns
let devicesPropertyValues = {}; // to store all the properties of devices
const heightRow = 36;


/**
 * @param {Object} props
 * @param {Array.<cx.ods.meta.Property>} props.properties
 */

function HeaderListItem(props) {
	const { fc } = useI18n();
	headerRows = [];

	const writerToHeaderRows = (cellData) => {
		let row = headerRows[cellData.top];
		if (!row) row = headerRows[cellData.top] = [];
		row[cellData.left] = cellData;
		return cellData;
	}

	const headerBuilder = (property, left, top) => {
		if (!property) return null;
		if (!property.element && !property.elements) {
			mainBodyColumns.push(property);
			return writerToHeaderRows({
				property,
				width: 1,
				left,
				top,
				ofTop: top
			});
		} else if (property.elements) {
			const cellData = {
				property,
				width: 0,
				left,
				top,
			}
			let positionFromLeft = left;
			property.elements.forEach(element => {
				const data = headerBuilder(element, positionFromLeft, top + 1);
				cellData.width = cellData.width + data.width;
				positionFromLeft = positionFromLeft + data.width;
			});
			return writerToHeaderRows(cellData);
		} else if (property.element) {
			return writerToHeaderRows({
				property,
				width: headerBuilder(property.element, left, top + 1).width,
				left,
				top,
			});
		}
	}

	let positionFromLeft = 0;
	const properties = [...props.properties];
	properties.unshift({
		propertyId: 'deviceName',
		name: fc('device name'),
	});
	properties.forEach(property => {
		const data = headerBuilder(property, positionFromLeft, 0);
		positionFromLeft = positionFromLeft + data.width;

	});

	const content = headerRows.map((row, index) => {
		return (row.map((cell) => {
			const style = {
				height: (cell.ofTop >= 0
					? (((headerRows.length - cell.ofTop) * heightRow) + 'px')
					: (heightRow + 'px')
				),
				top: (index * heightRow) + 'px',
				gridColumn: (cell.left + 1) + '/' + (cell.left + 1 + cell.width),
				gridRow: (cell.ofTop >= 0
					? ((cell.ofTop + 1) + '/' + (headerRows.length + 1))
					: ''
				),
			}
			return (cell
				? (<div
					key={cell.property.propertyId}
					className='header-item'
					style={style}
				>
					{cell.property.label || cell.property.name}
				</div>)
				: null
			);
		}));
	});

	return (content);
}


/**
 * @param {Object} props
 * @param {DeviceDetailsProxy} props.device
 */

function ReportListItem(props) {
	devicesPropertyValues[props.device.uri] = { deviceName: props.device.denomination() };

	const collectDevicePropertyValues = (properties) => {
		if (!properties) return null;
		if (Array.isArray(properties)) {
			properties.forEach(property => collectDevicePropertyValues(property));
		} else if (properties.elements || properties.element) {
			collectDevicePropertyValues(properties.elements || properties.element);
		} else devicesPropertyValues[props.device.uri][properties.propertyId] = properties.value;
	}

	collectDevicePropertyValues(props.device.properties || []);
	const items = mainBodyColumns.map(property => {
		let value = devicesPropertyValues[props.device.uri][property.propertyId] || '';
		if (property instanceof cx.ods.meta.DatetimeProperty) {
			if (value) {
				const date = new Date(value);
				date.setMilliseconds(0);
				value = formatDateTime(date);
			}
		}
		if (property instanceof cx.ods.meta.DateProperty) {
			value != '' && (value = cx.datetime.format.iso.date(new Date(value)));
		}
		const style = {
			height: heightRow + 'px',
			textAlign:
				property instanceof cx.ods.meta.IntegerProperty || property instanceof cx.ods.meta.DecimalProperty
					? 'right'
					: (property.propertyId == "deviceName" ? 'left' : 'center')
			,
			minWidth: property.propertyId == "deviceName" ? '140px' : ''
		};
		return (<div key={property.propertyId + '_' + props.device.uri} style={style} title={value}>
			{value}
		</div>);
	});

	return (items);
}

function DeviceRegistry({ devices, properties, dispatch, history }) {
	const { f } = useI18n();

	useEffect(() => {
		return () => devicesPropertyValues = {};
	}, []);

	const getCsvBody = () => {
		let result = '';

		const writeInString = (uri) => {
			let rowCsv = uri + ',';
			mainBodyColumns.forEach(property => {
				let value = devicesPropertyValues[uri][property.propertyId] || '';
				if (property instanceof cx.ods.meta.DatetimeProperty) {
					if (value) {
						const date = new Date(value);
						date.setMilliseconds(0);
						value = formatDateTime(date);
					}
				}
				rowCsv += (value + ',');
			});
			rowCsv += '\n';
			return rowCsv;
		}
		devices.forEach(device => {
			result += writeInString(device.uri);
		});
		return result;
	}

	const getCsvHeader = () => {
		const result = [];
		let commonLength = 0;
		headerRows.forEach((row, index) => {
			commonLength < row.length && (commonLength = row.length);
			result.push([]);
			result[index].push(index == 0 ? 'Uri' : '');
			for (let i = 0; i < commonLength; ++i) {
				let value = row[i] ? row[i].property.label || row[i].property.name : null;
				result[index].push(value || '');
			}
		});

		let stringValue = '';
		result.forEach(row => {
			row.forEach(cell => {
				stringValue += cell + ',';
			});
			stringValue += '\n';
		});
		return stringValue;
	}

	const exportReport = () => {
		let csv = '"' + f('report type') + '","' + f('generated at') + '",' + f('timezone') + '\n';
		csv += f('device registry');  // report type
		csv += ',"' + formatDateTime(cx.now()) + '"'; // generated at
		csv += ',' + getTimezoneString(); // timezone at
		csv += "\n\n";

		csv += getCsvHeader();
		csv += getCsvBody();

		dispatch(actions.deviceRegistry.exportDone({ csv }));
		history.goBack();
	}

	let content = null;
	let canExport = false;
	if (devices == null || properties == null) content = <div className="center"><Loader size={Loader.Size.MD} /></div>;
	else {
		canExport = true;
		mainBodyColumns = [];
		const items = [];
		items.push(devices.map(device => <ReportListItem key={device.uri} device={device} />));
		const style = {
			gridTemplateColumns: 'repeat(' + mainBodyColumns.length + ', auto)',
			gridTemplateRows: heightRow + 'px'
		}
		content = <ScrollList>
			<div className="device-registry" style={style}>
				<HeaderListItem key={1000000} properties={properties} />
				{items}
			</div>
		</ScrollList>
	}

	return (
		<div className="report device-registry">
			<ReportHeader title={f('device registry')} canExport={canExport} onExport={exportReport} />
			<ReportSummary generatedAt={new Date()} />
			<div className="content">
				{content}
			</div>
		</div>
	);
}

const expandBundles = (index, propertyIds) => {
	const expanded = new Map();
	const expand = property => {
		if (!isPropertyBundle(property)) expanded.set(property.propertyId, property);
		else property.elements.forEach(element => expand(index[element.elementId]));
	};
	propertyIds.map(propertyId => index[propertyId]).forEach(expand);
	return [...expanded.values()];
};

const fallbackProperties = (index, devices) => {
	const propertyIds = new Set();
	devices.forEach(device => {
		if (device.properties) device.properties.forEach(value => propertyIds.add(value.propertyId));
	});
	return Object.values(index).filter(property => propertyIds.has(property.propertyId)); 
};

export default connect(state => {
	const { parameters } = state.reports.deviceRegistry;
	const devices = 0 < parameters.uris?.length ? parameters.uris.map(uri => state.devices.map[uri]) : state.devices.list;
	const { map: propertyIndex } = state.meta.properties;
	const properties = !propertyIndex ? null : 0 < parameters.propertyIds?.length 
		? expandBundles(propertyIndex, parameters.propertyIds) 
		: fallbackProperties(propertyIndex, devices)
	; 
	return ({ devices, properties })
})(withRouter(DeviceRegistry));
